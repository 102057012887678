<template>
  <b-card class="card-custom rounded-top-0">
    <b-row>
      <b-col lg="3">
        <b-nav
          vertical
          class="nav-pills-custom position-sticky"
          :style="{ top: '130px' }"
          v-scroll-spy-active
          v-scroll-spy-link="{ selector: 'a.nav-link' }"
        >
          <b-nav-item href="javascript:;">Cooperative Information</b-nav-item>
          <b-nav-item href="javascript:;">Bank Information</b-nav-item>
          <b-nav-item href="javascript:;">Managing Agent</b-nav-item>
          <b-nav-item href="javascript:;">Accounts Receivable</b-nav-item>
          <b-nav-item href="javascript:;">Transfer Agent</b-nav-item>
          <b-nav-item href="javascript:;">Insurance Broker</b-nav-item>
          <b-nav-item href="javascript:;">Credit Criteria</b-nav-item>
          <b-nav-item href="javascript:;">Fees</b-nav-item>
          <b-nav-item href="javascript:;">Forms</b-nav-item>
          <b-nav-item href="javascript:;">Instructions</b-nav-item>
          <b-nav-item href="javascript:;">Applicants</b-nav-item>
        </b-nav>
      </b-col>
      <b-col lg="9" v-if="detailsData">
        <div
          class="kt-wizard-inside__content border-top border-top-lg-0 border-bottom-0 border-lg-left pb-0 mb-0 mt-7 mt-lg-0"
        >
          <b-row>
            <b-col>
              <div v-scroll-spy="{ offset: 130 }" class="pt-7 pt-lg-0 pl-lg-9">
                <div id="cooperativeInformation">
                  <div class="table-responsive">
                    <table class="table table-borderless text-reset mb-0">
                      <tbody>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h3 class="text-primary mb-0">Details</h3>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Company Name</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.company_name }}</td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Address</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Street Address</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.street_address }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Apt/Suite</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.suite }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">City</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.city }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">State</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.state }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Zip</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.zip }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="separator separator-solid my-10" />
                </div>

                <div id="cooperativeBankInformation">
                  <div class="table-responsive">
                    <table class="table table-borderless text-reset mb-0">
                      <tbody>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h3 class="text-primary mb-0">Bank Information</h3>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Bank's Name</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.bank_name }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Bank's Address</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.bank_address }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Apt/Suite</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.bank_suite }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">City</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.bank_city }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">State</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.bank_state }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Zip</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.bank_zip }}</td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Account Details</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Routing Number</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.routing_number }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Accounting number</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.account_number }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="separator separator-solid my-10" />
                </div>

                <div id="cooperativeManagingAgent">
                  <div class="table-responsive">
                    <table class="table table-borderless text-reset mb-0">
                      <tbody>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h3 class="text-primary mb-0">Managing Agent</h3>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Managing Agent</th>
                          <td class="pr-0" style="width: 70%;">false</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Company's Name</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.company_name }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">First Name</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.first_name }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Last Name</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.last_name }}</td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Contact Information</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Email Address</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.email }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Phone Number</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.phone }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Street Address</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.street_address }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Apt/Suite</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.suite }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">City</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.city }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">State</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.state }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Zip</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.zip }}</td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Account Details</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Routing Number</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.routing_number }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Accounting number</th>
                          <td class="pr-0" style="width: 70%;">{{ agentData.account_number }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="separator separator-solid my-10" />
                </div>

                <div id="cooperativeAccountsReceivable">
                  <div class="table-responsive">
                    <table class="table table-borderless text-reset mb-0">
                      <tbody>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h3 class="text-primary mb-0">Accounts Receivable</h3>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">First Name</th>
                          <td class="pr-0" style="width: 70%;">{{ accountData.first_name }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Last Name</th>
                          <td class="pr-0" style="width: 70%;">{{ accountData.last_name }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Email Address</th>
                          <td class="pr-0" style="width: 70%;">{{ accountData.email }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="separator separator-solid my-10" />
                </div>

                <div id="cooperativeTransferAgent">
                  <div class="table-responsive">
                    <table class="table table-borderless text-reset mb-0">
                      <tbody>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h3 class="text-primary mb-0">Transfer Agent</h3>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">First name</th>
                          <td class="pr-0" style="width: 70%;">{{ transferData.first_name }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Last name</th>
                          <td class="pr-0" style="width: 70%;">{{ transferData.last_name }}</td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Contact Information</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Email Address</th>
                          <td class="pr-0" style="width: 70%;">{{ transferData.email }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Phone Number</th>
                          <td class="pr-0" style="width: 70%;">{{ transferData.phone }}</td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Address</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Street Address</th>
                          <td class="pr-0" style="width: 70%;">{{ transferData.street_address }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Apt/Suite</th>
                          <td class="pr-0" style="width: 70%;">{{ transferData.suite }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">City</th>
                          <td class="pr-0" style="width: 70%;">{{ transferData.city }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">State</th>
                          <td class="pr-0" style="width: 70%;">{{ transferData.state }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Zip</th>
                          <td class="pr-0" style="width: 70%;">{{ transferData.zip }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="separator separator-solid my-10" />
                </div>

                <div id="cooperativeInsuranceBroker">
                  <div class="table-responsive">
                    <table class="table table-borderless text-reset mb-0">
                      <tbody>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h3 class="text-primary mb-0">Insurance Broker</h3>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">First Name</th>
                          <td class="pr-0" style="width: 70%;">{{ brokerData.first_name }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Last Name</th>
                          <td class="pr-0" style="width: 70%;">{{ brokerData.last_name }}</td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Contact Information</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Email Address</th>
                          <td class="pr-0" style="width: 70%;">{{ brokerData.email }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Phone Number</th>
                          <td class="pr-0" style="width: 70%;">{{ brokerData.phone }}</td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Address</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Street Address</th>
                          <td class="pr-0" style="width: 70%;">{{ brokerData.street_address }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Apt/Suite</th>
                          <td class="pr-0" style="width: 70%;">{{ brokerData.suite }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">City</th>
                          <td class="pr-0" style="width: 70%;">{{ brokerData.city }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">State</th>
                          <td class="pr-0" style="width: 70%;">{{ brokerData.state }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Zip</th>
                          <td class="pr-0" style="width: 70%;">{{ brokerData.zip }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="separator separator-solid my-10" />
                </div>

                <div id="cooperativeCreditCriteria">
                  <div class="table-responsive">
                    <table class="table table-borderless text-reset mb-0">
                      <tbody>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h3 class="text-primary mb-0">Credit Criteria</h3>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Maximum Loan to Value Ratio</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.maximum_loan_ratio }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Maximum Debt to Income Ratio</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.maximum_debt_ratio }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Minimum Credit Score</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.minimum_credit_score }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="separator separator-solid my-10" />
                </div>

                <div id="cooperativeFees">
                  <div class="table-responsive">
                    <table class="table table-borderless text-reset mb-0">
                      <tbody>
              <!--           <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h3 class="text-primary mb-0">Fees</h3>
                          </td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Refundable</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Move In Fee</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ moneyDollarForamter(detailsData.refundable_move_in_fee) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Move Out Fee</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ moneyDollarForamter(detailsData.refundable_move_out_fee) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Carpeting Fee</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ moneyDollarForamter(detailsData.refundable_carpeting_fee) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Non Refundable</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Move In Fee</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ moneyDollarForamter(detailsData.unrefundable_move_in_fee) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Move Out Fee</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ moneyDollarForamter(detailsData.unrefundable_move_out_fee) }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Flip Tax</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">{{ detailsData.select_flip_tax }}</th>
                          <td class="pr-0" style="width: 70%;">
                            {{
                              isSelectFlipTaxPercen(detailsData.select_flip_tax)
                                ? detailsData.flip_tax
                                : moneyDollarForamter(detailsData.flip_tax)
                            }}
                          </td>
                        </tr>
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Other Fees</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Pet Fee</th>
                          <td class="pr-0" style="width: 70%;">{{ moneyDollarForamter(detailsData.pet_fee) }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Managing Agent Fee</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ moneyDollarForamter(detailsData.managing_agent_fee) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Misc Cooperative Fee</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ moneyDollarForamter(detailsData.misc_cooperative_fee) }}
                          </td>
                        </tr> -->
                        <tr>
                          <td class="pl-0 pr-0" colspan="2">
                            <h6 class="text-primary mt-3 mb-0">Application Fee</h6>
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Application Fee</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ moneyDollarForamter(detailsData.application_fee) }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Each Applicant Pays the Application Fee</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ detailsData.is_each_applicants_pays_fee ? 'Yes' : 'No' }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="separator separator-solid my-10" />
                </div>

                <div id="cooperativeForms">
                  <div class="table-responsive">
                    <table class="table table-borderless text-reset mb-0">
                      <tbody>
                        <td class="pl-0 pr-0" colspan="2">
                          <h3 class="text-primary mb-0">Forms</h3>
                        </td>
                        <tr>
                          <th class="pl-0">Supplemental Application Form</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ detailsData.use_supplemental_forms ? 'Yes' : 'No' }}
                          </td>
                        </tr>

                        <template v-if="detailsData.use_supplemental_forms">
                          <tr v-for="doc in supplementals" :key="doc.id">
                            <th class="pl-0">{{ doc.name }}</th>
                            <td class="pr-0" style="width: 70%;">
                              <a v-if="doc.file" :href="doc.file" class="mr-5" target="_blank">
                                <i class="flaticon2-download"></i>
                              </a>
                              <span v-else>N/A</span>
                            </td>
                          </tr>
                        </template>

                        <tr>
                          <th class="pl-0">Criminal Check, Sex Offender Registry Check, Credit Check ($125)</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.background_check ? 'Yes' : 'No' }}</td>
                        </tr>
                 <!--        <tr>
                          <th class="pl-0">Housing Court Check (+$30)</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.housing_court_check ? 'Yes' : 'No' }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Sex Offender Registry Check (+$30)</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ detailsData.sex_offender_registry_check ? 'Yes' : 'No' }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Credit Check (+$30)</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.credit_check ? 'Yes' : 'No' }}</td>
                        </tr> -->
                      </tbody>
                    </table>
                  </div>
                  <div class="separator separator-solid my-10" />
                </div>

                <div id="cooperativeInstructions">
                  <div class="table-responsive">
                    <table class="table table-borderless text-reset mb-0">
                      <tbody>
                        <td class="pl-0 pr-0" colspan="2">
                          <h3 class="text-primary mb-0">Instructions</h3>
                        </td>
                        <tr>
                          <th class="pl-0">Who schedules the interview</th>
                          <td class="pr-0" style="width: 70%;">{{ detailsData.who_schedules_interview }}</td>
                        </tr>
                        <tr>
                          <th class="pl-0">Additional Instruction</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ detailsData.additional_instruction }}
                          </td>
                        </tr>
                        <tr>
                          <th class="pl-0">Remarks</th>
                          <td class="pr-0" style="width: 70%;">
                            {{ detailsData.remarks }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div class="separator separator-solid my-10" />
                </div>

                <div id="cooperativeApplicants">
                  <div class="table-responsive">
                    <table class="table table-borderless text-reset mb-0">
                      <tbody>
                        <td class="pl-0 pr-0" colspan="2">
                          <h3 class="text-primary mb-0">Invited Applicants</h3>
                        </td>
                        <tr>
                          <th class="pl-0">Emails</th>
                          <td class="pr-0" style="width: 70%;">{{ invitedEmails }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'cooperatives-view-details',
  props: ['detailsData'],
  data() {
    return {
      contactTypes: {
        'Managing Agent': 'agentData',
        'Accounts Receivable': 'accountData',
        'Transfer Agent': 'transferData',
        'Insurance Broker': 'brokerData',
      },
      agentData: {
        id: null,
        contact_type: 'Managing Agent',
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        street_address: '',
        suite: '',
        city: '',
        state: '',
        zip: '',
        routing_number: '',
        account_number: '',
      },
      accountData: {
        id: null,
        contact_type: 'Accounts Receivable',
        first_name: '',
        last_name: '',
        email: '',
      },
      transferData: {
        id: null,
        contact_type: 'Transfer Agent',
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        street_address: '',
        suite: '',
        city: '',
        state: '',
        zip: '',
        routing_number: '',
        account_number: '',
      },
      brokerData: {
        id: null,
        contact_type: 'Insurance Broker',
        company_name: '',
        first_name: '',
        last_name: '',
        email: '',
        phone: '',
        street_address: '',
        suite: '',
        city: '',
        state: '',
        zip: '',
        routing_number: '',
        account_number: '',
      },
    };
  },
  computed: {
    cooperativeId() {
      return this.$route.params.cooperativeId;
    },
    ...mapGetters({
      applicants: 'applicants/items',
      supplementals: 'supplemental/items',
    }),
    invitedEmails() {
      return this.applicants.map((a) => this.get(a, 'attributes.email')).join(', ');
    },
  },
  methods: {
    ...mapActions({
      getSupplemental: 'supplemental/index',
      getApplicants: 'applicants/index',
      getContacts: 'contacts/index',
    }),
    async getContactData(type) {
      const params = {
        elasticsearch: true,
        per: Math.pow(10, 12),
        page: 1,
        where: {
          cooperative_id: this.cooperativeId,
        },
      };
      await this.getContacts(params).then((data) => {
        (data || []).map((contact) => {
          const contactType = contact.attributes.contact_type;
          const dataType = this.contactTypes[contactType];
          if (!this[dataType]) {
            return contact;
          }
          this[dataType].id = contact.id;
          Object.keys(contact.attributes).map((el) => {
            this[dataType][el] = contact.attributes[el];
            return el;
          });
          return contact;
        });
      });
    },
    async getApplicantsData(type) {
      const params = {
        elasticsearch: true,
        per: Math.pow(10, 12),
        page: 1,
        where: {
          cooperative_id: this.cooperativeId,
        },
      };
      await this.getApplicants(params);
    },
  },
  async mounted() {
    this.getContactData();
    this.getApplicantsData();
    this.getSupplemental({
      where: {
        cooperative_id: this.cooperativeId,
      },
    });
    this.$root.$emit('changeTitle', this.detailsData.company_name);
  },
};
</script>
